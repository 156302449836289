import React from 'react';
import Stack from '../Stack/Stack';
import { Head3, Body1 } from '../Typography/Typography';

function DevSection({ section, sectionProps, SectionComponent }) {
    const data = section?.document?.data || section?.data;
    const body = data.body[0];
    return (
        <div>
            <hr
                style={{
                    borderWidth: '3px',
                    borderColor: 'black',
                    margin: 0,
                }}
            />
            <Stack
                space="0.25em"
                paddingX="20px"
                css={{
                    strong: {
                        fontWeight: 'bold',
                    },
                }}
            >
                <Head3>
                    Id: <strong>{section?.uid}</strong>
                </Head3>
                <Head3>
                    Type:{' '}
                    <strong>
                        {body.__typename.split('PrismicSectionDataBody').pop()}
                    </strong>
                </Head3>
                {data?.background && (
                    <Body1>
                        Background: <strong>{data?.background}</strong>
                    </Body1>
                )}
            </Stack>
            <SectionComponent {...sectionProps} />
            <hr
                style={{
                    borderWidth: '3px',
                    borderColor: 'black',
                    margin: 0,
                }}
            />
        </div>
    );
}

export default DevSection;
