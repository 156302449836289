import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import DevSection from '../components/DevSection/DevSection';
import SectionSlots from '../slots/SectionSlots/SectionSlots';
import { SCHEMA_PAGE_QUERY } from './__generated__/SCHEMA_PAGE_QUERY';

function SchemaPage({ data }: { data: SCHEMA_PAGE_QUERY }) {
    const {
        allPrismicSection: { nodes },
    } = data;
    const sections = useMemo(() => nodes.map((node) => ({ section: node })), [
        nodes,
    ]);
    return (
        <div>
            {/* @ts-expect-error */}
            <SectionSlots sections={sections} Wrapper={DevSection} />
        </div>
    );
}

export const _ = graphql`
    query SCHEMA_PAGE_QUERY {
        allPrismicSection {
            nodes {
                ...SECTIONS_FRAGMENT
            }
        }
    }
`;

export default SchemaPage;
